<template>
  <div>
    <h1>{{ 'about' | translate }}</h1>
  </div>
</template>

<script>
export default {
  name: "about"
}
</script>

<style scoped>

</style>